import React, { useState, useEffect, useRef } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconProfileCard,
  OutsideClickHandler,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import { checkValidSeller, onGetCartItems } from '../../../../util/data';

import { primaryCategory } from '../../../../config/configListing';
import { validSearchCat } from '../../../CMSPage/CMSPage.duck';
import { useHistory } from 'react-router-dom';

import css from './TopbarDesktop.module.css';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues
  } = props;

  const history = useHistory();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  const { cartListings, saveLaterListings } = onGetCartItems(currentUser);
  const [isOpen, setIsOpen] = useState(false);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  const isValidSeller = checkValidSeller(currentUser);

  return (
    <nav className={classes}>
      <div className={css.menubarDesktop}>
        <div className={css.menuButton}>
          <div className={css.leftProfileSection}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(true);
            }}>
            <span>{profileMenuMaybe}</span><span><IconProfileCard type="menu" /></span>
          </div>
          {isOpen ? (
            <OutsideClickHandler className={css.outsideHandle} onOutsideClick={() => setIsOpen(false)}>
              <div className={isAuthenticated ? css.menuDropDownLogin : css.menuDropDownLogOut}>
                <div className={css.dropdownWrapper}>
                  <div className={css.dropdownLink}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen("CATEGORIES")
                    }}>
                    <span><FormattedMessage id="TopbarDesktop.categoriesLink" /></span>
                  </div>

                  {isOpen == "CATEGORIES"
                    ? <div className={css.categoryWrapper}>{validSearchCat.map((st) => {
                      return <div className={css.categoryDropdown} key={st.option} onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                      }}>
                        <NamedLink
                          className={css.categoryDropdown}
                          name="CMSPage"
                          params={{ pageId: st.label || "000" }}
                        >
                          {st.option}
                        </NamedLink>
                      </div>
                    })}</div>
                    : <>
                      <div className={css.dropdownLink}>
                        <NamedLink
                          className={css.links}
                          name="CartPage"
                        >
                          <span><FormattedMessage id="TopbarDesktop.cartLink" /></span>
                        </NamedLink>
                      </div>

                      {isAuthenticated ? null : <>
                        <div className={css.dropdownLink}>
                          {signupLinkMaybe}
                        </div>
                        <div className={css.dropdownLink}>
                          {loginLinkMaybe}
                        </div>
                      </>}

                      {isAuthenticated && isValidSeller ? <div className={css.dropdownLink}>
                        <NamedLink
                          className={css.links}
                          name="ProfileSettingsPage"
                        >
                          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
                        </NamedLink>

                      </div> : null}

                      {isValidSeller ? null
                        : <div className={css.dropdownLink}>
                          <NamedLink
                            className={css.links}
                            name="ApplyToSellPage"
                          >
                            <FormattedMessage id="TopbarDesktop.applyToSellLink" />
                          </NamedLink>

                        </div>
                      }

                      {isAuthenticated ? <>
                        <div className={css.dropdownLink}>
                          <NamedLink
                            className={css.links}
                            name="AccountSettingsPage"
                          >
                            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
                          </NamedLink>

                        </div>
                        <div className={css.dropdownLink}>
                          <NamedLink
                            className={css.links}
                            name="InboxBasePage"
                          >
                            <FormattedMessage id="TopbarDesktop.OrdersInboxLink" />
                          </NamedLink>

                        </div>
                        <div className={css.dropdownLink} onClick={onLogout}>
                          <FormattedMessage id="TopbarDesktop.logout" />
                        </div>
                      </>
                        : null}

                    </>}

                </div>

                <div className={css.crossButton}
                  onClick={(e) => {
                    e.preventDefault();
                    isOpen == "CATEGORIES" ? setIsOpen("MENU") : setIsOpen(false);
                  }}><IconProfileCard type="close" />
                </div>
              </div>
            </OutsideClickHandler>
          )
            : null}
        </div>
        <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          linkToExternalSite={config?.topbar?.logoLink}
        />
        {/* <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      /> */}

        {/* <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}
        {/* 
      {inboxLinkMaybe}
      {/* {signupLinkMaybe}
      {loginLinkMaybe} */}
        <div className={css.rightTopSection}>
          <span>
            <NamedLink
              className={css.listingLink}
              name="SearchPage"
            >
              <IconProfileCard type="search" />
            </NamedLink>
          </span>
          <span>
            <NamedLink
              className={css.cartLink}
              name="CartPage"
            >
              <IconProfileCard type="cart" />
              {cartListings.length > 0 && <div className={css.cartItems}>{cartListings.length}</div>}
            </NamedLink>
          </span>
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
